<template>
  <div
    @click="cancelDelete"
    v-if="visible"
    class="inspection-oilInspection-panel">
    <!-- 加载蒙版 -->
    <div
      element-loading-background="rgba(0, 0, 0, 0.5)"
      v-show="loading"
      class="inspection-loading"
      v-loading="loading"
    ></div>
    <div class="panel-title">
      <b></b>
      <span class="title-text">{{ $t("inspection.info") }}</span>
      <i @click="handleClose" class="el-icon-close pointer-icon"></i>
    </div>
    <div class="panel-info">
      <div class="entity-info">
        <img width="16px" :src="`/img/map/${drawType}-active.png`" alt="" />
        <el-input
          :disabled="!canEdit"
          style="width: 160px; margin-left: 5px"
          size="small"
          v-model="input"
          @blur="inspectionUpdate"
        />
        <i
          @click="flyTo"
          style="margin-left: 5px"
          class="el-icon-aim pointer-icon"
        >
        </i>
        <i
          :class="deleteClass"
          @click.stop="deletedMark"
          style="margin-left: 5px"
          class="el-icon-delete pointer-icon"
        >
        </i>
      </div>
      <div class="entity-item">
        <div class="item-title">{{ $t("folder") }}</div>
        <div class="item-content-box">
          <el-select
            :disabled="!canEdit"
            size="small"
            style="width: 150px"
            @change="pathChange"
            v-model="path"
            placeholder="请选择"
          >
            <el-option
              v-for="item in pathList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="panel-info">
      <el-collapse accordion :value="1">
        <el-collapse-item :name="1">
          <template slot="title">
            <div class="collapse-panel-Separator">
              {{ $t("inspection.baseInfo") }}
            </div>
          </template>
          <div class="entity-item">
            <div style="width: 80px" class="item-title">{{ $t("type") }}</div>
            <div class="item-content-box">
              {{ $t(`inspection.${drawType}`) }}
            </div>
          </div>
          <!-- 管线长度 -->
          <div class="entity-item" v-if="type === NodeMap.Node.LINE">
            <div style="width: 80px" class="item-title">
              {{ $t("inspection.PipelineLength") }}
            </div>
            <div class="item-content-box">
              {{ distance }}
            </div>
          </div>
          <!-- 属地单位 -->
          <div class="entity-item">
            <div style="width: 80px" class="item-title">
              {{ $t("inspection.possessionUnit") }}
            </div>
            <div class="item-content-input">
              <select-tree
                :disabled="!canEdit"
                :data="deptList"
                @select-change="changeDept"
                style="width: 150px; margin-left: 5px"
                size="small"
                :placeholder="$t('selectPlease')"
                v-model="deptId"
              >
              </select-tree>
            </div>
          </div>
          <!-- 计量站配水间 -->
          <div class="entity-item">
            <div style="width: 80px" class="item-title">
              {{ $t("inspection.meteringstation") }}/{{ $t("inspection.waterRoom") }}
            </div>
            <div class="item-content-input">
              <el-input
                :disabled="!canEdit"
                size="small"
                @blur="inspectionUpdate"
                style="width: 150px; margin-left: 5px"
                v-model="meteringStation"
                :placeholder="$t('inputPlease')"
              >
              </el-input>
            </div>
          </div>
          <!-- 联系人 -->
          <div class="entity-item">
            <div style="width: 80px" class="item-title">
              {{ $t("contactPerson") }}
            </div>
            <div class="item-content-input">
              <el-autocomplete
                :disabled="!canEdit"
                :trigger-on-focus="false"
                size="small"
                style="width: 150px; margin-left: 5px"
                filterable
                v-model="linkman"
                allow-create
                @blur="selectLinkName"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                @select="selectLinkName"
              >
              </el-autocomplete>
            </div>
          </div>
          <!-- 联系电话 -->
          <div class="entity-item">
            <div style="width: 80px" class="item-title">
              {{ $t("contactTelephone") }}
            </div>
            <div class="item-content-input">
              <el-input
                :disabled="!canEdit"
                size="small"
                :placeholder="$t('inputPlease')"
                v-model="tel"
                @blur="inspectionUpdate"
                style="width: 150px; margin-left: 5px"
              >
              </el-input>
            </div>
          </div>
          <!-- 创建人 -->
          <div class="entity-item">
            <div style="width: 80px" class="item-title">
              {{ $t("inspection.createBy") }}
            </div>
            <div class="item-content-box">
              {{ createBy }}
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-if="type === NodeMap.Node.LINE" class="panel-last edit-box">
      <div class="panel-Separator">
        {{ $t("inspection.pointInfo") }}
      </div>
      <div v-if="!editPoint" class="edit-point-box">
        <div
          v-for="(item, index) in linePositions"
          :key="item.id"
          class="entity-item entity-line-item"
        >
          <div class="entity-line-index">{{ index + 1 }}</div>
          <div>[{{ item.lon.toFixed(7) }}, {{ item.lat.toFixed(7) }}]</div>
          <i
            @click="editPointHandle(item, index)"
            class="el-icon-edit"
            :class="!canEdit ? 'cannotEdit' : ''"
          ></i>
          <i
            @click="plusPoint(item, index)"
            class="el-icon-plus"
            :class="!canEdit ? 'cannotEdit' : ''"
          ></i>
          <i
            @click.stop="deletePoint(item, index)"
            :class="
              !canEdit
                ? 'cannotEdit'
                : deletePointId === item.id
                ? 'bgc-red'
                : ''
            "
            class="el-icon-delete"
          ></i>
        </div>
      </div>
      <div v-else class="edit-point-box">
        <div class="entity-line-index">{{ pointInfo.index + 1 }}</div>
        <el-form size="mini" :model="formInline" class="demo-form-inline">
          <el-form-item :label="$t('longitude')">
            <el-input-number
              :disabled="!canEdit"
              :controls="false"
              @blur="inspectionUpdate"
              controls-position="right"
              style="width: 130px; margin-left: 5px"
              v-model="pointInfo.lon"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item :label="$t('latitude')">
            <el-input-number
              :disabled="!canEdit"
              :controls="false"
              @blur="inspectionUpdate"
              controls-position="right"
              style="width: 130px; margin-left: 5px"
              v-model="pointInfo.lat"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button @click="cancelEditHandle">取消</el-button>
            <el-button
              :loading="saveLinePointLoading"
              type="primary"
              @click="saveEditHandle"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-else class="panel-last edit-box">
      <div class="panel-Separator">
        {{ $t("inspection.survey") }}
      </div>
      <div>
        <div class="entity-item">
          <div class="item-title">{{ $t("longitude") }}</div>
          <div class="item-content-box">
            <el-input-number
              :disabled="!canEdit"
              size="small"
              style="width: 130px; margin-left: 5px"
              v-model="lng"
              :controls="false"
              @blur="inspectionUpdate"
              controls-position="right"
            >
            </el-input-number>
            °
          </div>
        </div>
        <div class="entity-item">
          <div class="item-title">{{ $t("latitude") }}</div>
          <div class="item-content-box">
            <el-input-number
              :disabled="!canEdit"
              size="small"
              style="width: 130px; margin-left: 5px"
              v-model="lat"
              :controls="false"
              @blur="inspectionUpdate"
              controls-position="right"
            >
            </el-input-number>
            °
          </div>
        </div>
      </div>
    </div>
    <div class="advanced-setting">
      <span class="setting-label" @click="showSetting">
        {{ $t("inspection.advancedSetting") }}
      </span>
    </div>
    <el-dialog
      width="450px"
      :title="$t('inspection.advancedSetting')"
      :visible.sync="settingShow"
      :before-close="closeSetting"
      class="common-map-dialog private-inspection-oilInspection-panel-dialog"
      :close-on-click-modal="false"
      append-to-body
    >
      <div
        element-loading-background="rgba(0, 0, 0, 0.5)"
        v-show="settingLoading"
        class="inspection-loading"
        v-loading="settingLoading"
      ></div>
      <div>
        <div>
          <span class="setting-left-label">{{
            $t("inspection.intoMessage1")
          }}</span>
          <div class="item-content-box">
            <el-input
              :disabled="!canEdit"
              class="uav-input"
              size="small"
              style="width: 130px; margin-left: 5px"
              v-model="startCondition"
              controls-position="right"
            >
            </el-input>
          </div>
          <span style="margin-left: 8px">{{
            $t("inspection.intoMessage2")
          }}</span>
        </div>
        <!-- <div style="margin-top: 16px">
          <span class="setting-left-label">{{
            $t("inspection.outtoMessage1")
          }}</span>
          <div class="item-content-box">
            <el-input
              :disabled="!canEdit"
              class="uav-input"
              size="small"
              style="width: 130px; margin-left: 5px"
              v-model="endCondition"
              controls-position="right"
            >
            </el-input>
          </div>
          <span style="margin-left: 8px">{{
            $t("inspection.outtoMessage2")
          }}</span>
        </div> -->
        <div style="margin-top: 16px; padding-left: 16px; color: #ffffff">
          <el-checkbox v-model="applyAll">
            {{ `${$t("inspection.useToAll")}` }}{{ $t(`inspection.${drawType}`) }}
          </el-checkbox>
        </div>
      </div>
      <div v-if="canEdit" slot="footer">
        <el-button @click="closeSetting">{{ $t("cancelText") }}</el-button>
        <el-button
          :loading="submitLoading"
          @click="submitSetting"
          type="primary"
          >{{ $t("submitText") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { colors } from "../js/mapConfig";
import { getStore } from "@/util/store";
import { getList } from "@/api/system/dept";
import { getuserList } from "@/api/system/user";
import NodeMap from "@/components/cesium/js/node/index";
import {
  getOilwellpoint,
  setOilwellpoint,
  getOilline,
  setOilline,
  getStorageTank,
  setStorageTank,
  getMeasurementStation,
  setMeasurementStation,
  getStationStorage,
  setStationStorage,
  getOilRoad,
  setOilRoad,
  getPowerLine,
  setPowerLine,
  getPowerEquipment,
  setPowerEquipment,
  getOilGasWell,
  setOilGasWell,
} from "@/api/project/map";

export default {
  name: "MapRightPanel",
  props: {
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    entity: {
      type: Object,
      default() {
        return {};
      },
    },
    pathList: {
      type: Array,
      default() {
        return [];
      },
    },
    canEdit: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  watch: {
    entity: {
      handler(value) {
        if (value && value.id) {
          // 这种写法以后有时间改进
          this.drawType = value.drawType;
          this.input = value.text;
          this.id = value.id;
          this.color = value.color;
          this.lng = value.lng;
          this.lat = value.lat;
          this.height = value.height;
          this.type = value.type;
          this.distance = value.distance;
          this.createBy = value.createBy;
          this.clickOne = false;
          this.path = value.path;
          this.linePositions = value.linePositions;
          this.deptId = value.deptId ? value.deptId : "";
          this.linkman = value.linkman;
          this.tel = value.tel;
          this.meteringStation = value.meteringStation;
          this.startCondition = value.startCondition;
          this.endCondition = value.endCondition;
        }
      },
      deep: true,
    },
    visible() {
      this.deletePointId = "";
    },
  },
  data() {
    return {
      NodeMap,
      settingShow: false,
      submitLoading: false,
      loading: false,
      tenantId: getStore({
        name: "tenantId",
      }),
      clickOne: false,
      color: "#0096FF",
      colors: [],
      // 记录当前entity的id
      id: "",
      input: "",
      // 几何类型
      type: "",
      // 业务类型
      drawType: "",
      path: "",
      lng: null,
      lat: null,
      height: null,
      distance: "0m",
      createBy: "",
      linePositions: [],
      linkman: "",
      linkmanList: [],
      meteringStation: "",
      startCondition: "",
      endCondition: "",
      applyAll: false,
      tel: "",
      // 是否编辑
      editPoint: false,
      pointInfo: {
        index: 0,
        lon: 0,
        lat: 0,
      },
      saveLinePointLoading: false,
      deletePointId: "",
      deptId: "",
      deptList: [],
      deptProps: {
        label: "title",
        value: "value",
        checkStrictly: true,
        emitPath: false,
      },
      settingLoading: false
    };
  },
  computed: {
    deleteClass() {
      var className = "";
      if (this.clickOne) {
        className = "bgc-red";
      }
      if (!this.canEdit) {
        className = className + " cannotEdit";
      }
      return className;
    },
  },
  mounted() {
    this.colors = colors;
    this.getDeptTree();
  },
  methods: {
    handleClose() {
      this.$emit("close");
      this.clickOne = false;
      this.$emit("update:visible", false);
      this.reset();
    },
    reset() {
      this.input = "";
      this.id = "";
      this.color = "";
      this.lng = null;
      this.lat = null;
      this.height = null;
      this.type = "";
      this.distance = "";
      this.createBy = "";
      this.clickOne = false;
      this.path = "";
      this.linePositions = [];
      this.deptId = "";
      this.meteringStation = "";
      this.tel = "";
    },
    changeDept(detpId) {
      this.linkman = this.getDeptFieldById(detpId, "managerName");
      let tel = this.getDeptFieldById(detpId, "managerPhone");
      this.tel = tel ? tel : "";
      this.inspectionUpdate();
    },
    inspectionUpdate() {
      let deptId = this.deptId;
      let deptName = this.getDeptFieldById(deptId, "label");
      this.$emit("updateEntity", {
        id: this.id,
        text: this.input,
        lng: this.lng,
        lat: this.lat,
        height: this.height,
        type: this.type,
        positions: this.linePositions,
        deptId,
        linkman: this.linkman,
        tel: this.tel,
        meteringStation: this.meteringStation,
        deptName,
      });
    },
    flyTo() {
      if (this.type === NodeMap.Node.LINE) {
        this.$EventBus.$emit("fly-to-by-id", this.id);
      } else {
        this.$EventBus.$emit(
          "map-flyTo",
          parseFloat(this.lng),
          parseFloat(this.lat)
        );
      }
    },
    deletedMark(id) {
      if (!this.canEdit) {
        return;
      }
      if (this.clickOne) {
        this.$EventBus.$emit("delete-inspection-by-id", this.id);
        this.clickOne = false;
      } else {
        this.clickOne = true;
      }
    },
    editPointHandle(node, index) {
      if (!this.canEdit) {
        return;
      }
      this.pointInfo = {
        index: index,
        lon: node.lon,
        lat: node.lat,
      };
      this.editPoint = true;
    },
    saveEditHandle() {
      let position = this.linePositions[this.pointInfo.index];
      position.lon = this.pointInfo.lon;
      position.lat = this.pointInfo.lat;
      this.$emit(
        "updateEntity",
        {
          id: this.id,
          text: this.input,
          lng: this.lng,
          lat: this.lat,
          height: this.height,
          type: this.type,
          positions: this.linePositions,
        },
        () => {
          this.saveLinePointLoading = false;
          this.editPoint = false;
        }
      );
    },
    plusPoint(item, index) {
      if (!this.canEdit) {
        return;
      }
      this.$emit("addPoint", {
        id: this.id,
        text: this.input,
        lng: this.lng,
        lat: this.lat,
        height: this.height,
        type: this.type,
        positions: this.linePositions,
        item,
        index,
      });
    },
    deletePoint(item, index) {
      if (!this.canEdit) {
        return;
      }
      if (this.linePositions.length == 2) {
        this.$message.warning(this.$t("inspection.cannotdeleted"));
        return;
      }
      if (this.deletePointId !== item.id) {
        this.deletePointId = item.id;
      } else {
        this.$emit("removePoint", {
          id: this.id,
          text: this.input,
          lng: this.lng,
          lat: this.lat,
          height: this.height,
          type: this.type,
          positions: this.linePositions,
          item,
          index,
        });
        this.deletePointId = "";
      }
    },
    cancelEditHandle() {
      this.editPoint = false;
    },
    cancelDelete() {
      this.clickOne = false;
      this.deletePointId = "";
    },
    pathChange(value) {
      this.$emit("updateEntity", {
        id: this.id,
        text: this.input,
        lng: this.lng,
        lat: this.lat,
        height: this.height,
        path: this.path,
        type: this.type,
        positions: this.linePositions,
      });
    },
    getDeptTree() {
      getList()
        .then((result) => {
          if (result.data.code === 200) {
            let data = result.data.data;
            this.deptList = this.filterDeptTree(data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    filterDeptTree(tree) {
      return tree.map((item) => {
        item.label = item.deptName;
        item.value = item.id;
        if (item.children && Array.isArray(item.children)) {
          item.children = this.filterDeptTree(item.children);
        }
        return item;
      });
    },
    querySearchAsync(queryString, cb) {
      let restaurants = this.linkmanList;
      let results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;
      let detpId = this.deptId;
      getuserList(1, 100, {}, detpId).then((res) => {
        if (res.data.code == 200) {
          this.linkmanList = res.data.data.records.map((item) => {
            return {
              value: item.realName,
              tel: item.phone,
            };
          });
        }
        cb(results);
      });
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    filterDeptId(id, children) {
      if (Array.isArray(id)) {
        id = id[0];
      }
      if (!children) {
        children = this.deptList;
      }
      let res = [];
      let temp = [];
      for (var i = 0; i < children.length; i++) {
        var item = children[i];
        if (item.value === id) {
          return [id];
        }
        if (item.children && item.children.length > 0) {
          temp = this.filterDeptId(id, item.children);
          if (temp.length > 0) {
            res = [item.value].concat(temp);
            return res;
          } else {
            return [];
          }
        }
      }
      return [];
    },
    selectLinkName(node) {
      if (!this.$options.timer) {
        this.$options.timer = setTimeout(() => {
          this.inspectionUpdate();
        }, 1000);
      } else {
        if (node.hasOwnProperty("tel")) {
          clearTimeout(this.$options.timer);
          this.$options.timer = null;
          if (node.tel) {
            this.tel = node.tel;
          }
          this.inspectionUpdate();
        }
      }
    },
    getDeptFieldById(deptId, field, list) {
      if (!list) {
        list = this.deptList;
      }
      let name = "";
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (deptId === item.value) {
          return item[field];
        }
        if (item.children && item.children.length > 0) {
          name = this.getDeptFieldById(deptId, field, item.children);
        }
        if (name) {
          return name;
        }
      }
    },
    showSetting() {
      if (!this.id) {
        return;
      }
      if (!this.canEdit) {
        this.settingShow = true;
        return;
      }
      this.settingShow = true;
      this.settingLoading = true;
      this.getOilDetail()
        .then(() => {})
        .finally(() => {
          this.settingLoading = false;
        });
    },
    getOilDetail() {
      return new Promise((resolve, reject) => {
        switch (this.drawType) {
          case NodeMap.OilWellPoint.DRAWTYPE:
            getOilwellpoint({
              id: this.id,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                let data = res.data.data;
                this.startCondition = data.startCondition;
                this.endCondition = data.endCondition;
                resolve();
              }
            });
            break;
          case NodeMap.OilPipeline.DRAWTYPE:
            getOilline({
              id: this.id,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                let data = res.data.data;
                this.startCondition = data.startCondition;
                this.endCondition = data.endCondition;
                resolve();
              }
            });
            break;
          case NodeMap.StorageTank.DRAWTYPE:
            getStorageTank({
              id: this.id,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                let data = res.data.data;
                this.startCondition = data.startCondition;
                this.endCondition = data.endCondition;
                resolve();
              }
            });
            break;
          case NodeMap.MeasurementStation.DRAWTYPE:
            getMeasurementStation({
              id: this.id,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                let data = res.data.data;
                this.startCondition = data.startCondition;
                this.endCondition = data.endCondition;
                resolve();
              }
            });
            break;
          case NodeMap.StationStorage.DRAWTYPE:
            getStationStorage({
              id: this.id,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                let data = res.data.data;
                this.startCondition = data.startCondition;
                this.endCondition = data.endCondition;
                resolve();
              }
            });
            break;
          case NodeMap.OilRoad.DRAWTYPE:
            getOilRoad({
              id: this.id,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                let data = res.data.data;
                this.startCondition = data.startCondition;
                this.endCondition = data.endCondition;
                resolve();
              }
            });
            break;
          case NodeMap.PowerLine.DRAWTYPE:
            getPowerLine({
              id: this.id,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                let data = res.data.data;
                this.startCondition = data.startCondition;
                this.endCondition = data.endCondition;
                resolve();
              }
            });
            break;
          case NodeMap.PowerEquipment.DRAWTYPE:
            getPowerEquipment({
              id: this.id,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                let data = res.data.data;
                this.startCondition = data.startCondition;
                this.endCondition = data.endCondition;
                resolve();
              }
            });
            break;
          case NodeMap.GasWell.DRAWTYPE:
            getOilGasWell({
              id: this.id,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                let data = res.data.data;
                this.startCondition = data.startCondition;
                this.endCondition = data.endCondition;
                resolve();
              }
            });
            break;
          default:
            return;
        }
      });
    },
    closeSetting() {
      this.startCondition = "";
      this.endCondition = "";
      this.applyAll = false;
      this.settingShow = false;
    },
    submitSetting() {
      this.submitLoading = true;
      this.saveOilSetinng()
        .then(() => {
          this.$message.success("操作成功");
          this.closeSetting();
        })
        .catch(() => {})
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 保存高级设计
    saveOilSetinng() {
      return new Promise((resolve, reject) => {
        switch (this.drawType) {
          case NodeMap.OilWellPoint.DRAWTYPE:
            setOilwellpoint({
              id: this.id,
              startCondition: this.startCondition,
              endCondition: this.endCondition,
              applyAll: this.applyAll,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                resolve();
              } else {
                this.$message.error(res.data.msg);
              }
            });
            break;
          case NodeMap.OilPipeline.DRAWTYPE:
            setOilline({
              id: this.id,
              startCondition: this.startCondition,
              endCondition: this.endCondition,
              applyAll: this.applyAll,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                resolve();
              } else {
                this.$message.error(res.data.msg);
              }
            });
            break;
          case NodeMap.StorageTank.DRAWTYPE:
            setStorageTank({
              id: this.id,
              startCondition: this.startCondition,
              endCondition: this.endCondition,
              applyAll: this.applyAll,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                resolve();
              } else {
                this.$message.error(res.data.msg);
              }
            });
            break;
          case NodeMap.MeasurementStation.DRAWTYPE:
            setMeasurementStation({
              id: this.id,
              startCondition: this.startCondition,
              endCondition: this.endCondition,
              applyAll: this.applyAll,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                resolve();
              } else {
                this.$message.error(res.data.msg);
              }
            });
            break;
          case NodeMap.StationStorage.DRAWTYPE:
            setStationStorage({
              id: this.id,
              startCondition: this.startCondition,
              endCondition: this.endCondition,
              applyAll: this.applyAll,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                resolve();
              } else {
                this.$message.error(res.data.msg);
              }
            });
            break;
          case NodeMap.OilRoad.DRAWTYPE:
            setOilRoad({
              id: this.id,
              startCondition: this.startCondition,
              endCondition: this.endCondition,
              applyAll: this.applyAll,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                resolve();
              } else {
                this.$message.error(res.data.msg);
              }
            });
            break;
          case NodeMap.PowerLine.DRAWTYPE:
            setPowerLine({
              id: this.id,
              startCondition: this.startCondition,
              endCondition: this.endCondition,
              applyAll: this.applyAll,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                resolve();
              } else {
                this.$message.error(res.data.msg);
              }
            });
            break;
          case NodeMap.PowerEquipment.DRAWTYPE:
            setPowerEquipment({
              id: this.id,
              startCondition: this.startCondition,
              endCondition: this.endCondition,
              applyAll: this.applyAll,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                resolve();
              } else {
                this.$message.error(res.data.msg);
              }
            });
            break;
          case NodeMap.GasWell.DRAWTYPE:
            setOilGasWell({
              id: this.id,
              startCondition: this.startCondition,
              endCondition: this.endCondition,
              applyAll: this.applyAll,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                resolve();
              } else {
                this.$message.error(res.data.msg);
              }
            });
            break;
          default:
            reject();
            break;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.inspection-oilInspection-panel {
  height: 100%;
  box-sizing: border-box;
  color: #fff;
  background-color: #1e222a;
  width: 278px;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  .panel-title {
    padding: 0 16px;
    height: 45px;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #030303 solid;
  }
  .panel-info {
    flex-shrink: 0;
    padding: 16px 16px 16px 16px;
    border-bottom: 1px #030303 solid;
    .entity-colors {
      margin-top: 18px;
      .item-title {
        display: inline-block;
        vertical-align: top;
      }
      .item-content-box {
        display: inline-block;
        vertical-align: top;
      }
      .entity-checkbox {
        display: inline-flex;
        width: 21px;
        height: 21px;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        cursor: pointer;
      }
    }
    .entity-item {
      margin-top: 10px;
      .item-title {
        display: inline-block;
        width: 50px;
        vertical-align: middle;
        font-size: 14px;
      }
      .item-content-box {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }
      .item-content-input {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .entity-line-item {
      & > div {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin-right: 4px;
      }

      & > i {
        font-size: 12px;
        margin-right: 2px;
        cursor: pointer;
      }
      & > i:hover {
        background-color: #409eff;
      }
    }
    .entity-line-index {
      width: 24px;
      background: #454545;
      border-radius: 2px;
      opacity: 0.55;
      text-align: center;
      font-size: 14px;
    }
    .el-collapse {
      border: none;
      .el-collapse-item__header {
        background-color: transparent;
        border: none;
        color: #ffff;
        height: 21px;
        .collapse-panel-Separator {
          height: 100%;
          border-left: 4px solid rgba(64, 158, 255, 1);
          padding-left: 16px;
          line-height: 21px;
          font-size: 14px;
        }
      }
      .el-collapse-item__wrap {
        background-color: transparent;
        border-bottom: none;
        .el-collapse-item__content {
          color: #ffffff;
          font-size: 14px;
          padding-bottom: 0px;
        }
      }
    }
  }
  .panel-last {
    padding: 16px 16px 16px 16px;
    flex-grow: 1;
    font-size: 14px;
    .entity-colors {
      margin-top: 18px;
      .item-title {
        display: inline-block;
        vertical-align: top;
      }
      .item-content-box {
        display: inline-block;
        vertical-align: top;
      }
      .entity-checkbox {
        display: inline-flex;
        width: 21px;
        height: 21px;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        cursor: pointer;
      }
    }
    .entity-item {
      margin-top: 18px;
      .item-title {
        display: inline-block;
        width: 50px;
        vertical-align: middle;
      }
      .item-content-box {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }
      .item-content-input {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .entity-line-item {
      & > div {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin-right: 4px;
      }

      & > i {
        font-size: 12px;
        margin-right: 2px;
        cursor: pointer;
      }
      & > i:hover {
        background-color: #409eff;
      }
    }
    .entity-line-index {
      width: 24px;
      background: #454545;
      border-radius: 2px;
      opacity: 0.55;
      text-align: center;
      font-size: 14px;
    }
  }
  .advanced-setting {
    padding: 10px 20px;
    text-align: right;
    .setting-label {
      color: #409eff;
      cursor: pointer;
    }
  }
  .pointer-icon {
    cursor: pointer;
  }
  .el-input__inner {
    background-color: #000000 !important;
    border: 1px solid #535353 !important;
    color: #ffffff !important;
  }
  input::-webkit-input-placeholder {
    -webkit-text-fill-color: #535353 !important;
  }
  .bgc-red {
    background: red;
  }
  .bgc-red:hover {
    background: red !important;
  }
  .panel-Separator {
    padding-left: 16px;
    margin-bottom: 8px;
    border-left: 4px solid rgba(64, 158, 255, 1);
  }
  .edit-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .edit-point-box {
      margin-top: 2px;
      flex-grow: 1;
      overflow: auto;
    }
  }
  .cannotEdit {
    cursor: not-allowed !important;
  }
}
.private-inspection-oilInspection-panel-dialog {
  .item-content-box {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  .setting-left-label {
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    text-align: right;
  }
  .el-dialog__body {
    position: relative;
  }
  .inspection-loading {
    position: absolute !important;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
  }
}
</style>
